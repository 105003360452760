<template xmlns="http://www.w3.org/1999/html">
  <div
    v-if="!product.productHasExpired"
    class="ExtendedLayoutProductCard bg-lighter p-12 desk:p-16 mb-8 text-14 font-medium"
  >
    <div class="flex justify-between items-center">
      <!-- * * * image, name, isNews -->
      <div class="flex items-center pr-6 leading-[1.4em] basis-4/5">
        <div class="w-40 h-40 mr-12 flex relative">
          <nuxt-img
            v-if="product.image"
            :src="product.image"
            provider="norce"
            preset="standard"
            sizes="40px"
            alt=""
            class="w-full object-contain mix-blend-multiply"
          />
          <img
            v-else
            src="/icons/no-image.svg"
            alt="no image"
            class="w-full object-cover"
          >
        </div>

        <div class="flex flex-col tabletPortrait:flex-row tabletPortrait:items-center ">
          <div class="type-headline-sm">{{ product.name }}</div>
          <div
            v-if="isNews"
            class="tabletPortrait:ml-4"
          >
            <div
              class="inline-block whitespace-nowrap leading-single p-6 bg-brandPrimary text-brandDark type-headline-xxs"
            >
              {{ $t('productPage.extended.variantNews') }}
            </div>
          </div>
        </div>
      </div>
      <!-- * * * * price, availability -->
      <div class="flex items-center">
        <div class="flex flex-col type-sm-medium text-right items-end leading-single">
          <div v-if="userPrice.status === 'logginIn' || userPrice.status === 'pending'" class="priceLoadBox">
            {{ userPrice.price?.priceBeforeVatDisplay.replace(/\d/g, '0') }}
          </div>
          <div
            v-if="userPrice.status === 'notLoggedIn'"
            class="btn--text leading-18"
            @click.prevent="showLogin"
          >
            {{ $t('productPage.extended.notLoggedIn') }}
          </div>
          <span v-if="userPrice.status === 'ok'" class="whitespace-nowrap">
            {{ userPrice.price?.priceBeforeVatDisplay }}
          </span>
          <span
            v-if="(userPrice.price?.isCustomerPrice && userPrice.price?.discountPercentage > 0) || userPrice.price?.isCampaignPrice"
            class="hidden tabletPortrait:block"
          >
            {{ $t('productPage.price.original') }} {{ userPrice.price?.priceBeforeDiscountBeforeVatDisplay }}
            <span v-if="userPrice.price?.isCustomerPrice">
              ({{ $t('productPage.price.yourDiscount') }} <span
                class="text-criticalDark"
              >{{ userPrice.price?.discountPercentage }}%</span>)
            </span>
          </span>
          <StockStatusDesign
            v-if="!uiStore.isMobile || uiStore.isTabletPortrait || stockLabel.type === 'active' || stockLabel.type === 'soldout'"
            class="font-normal mt-2"
            :nowrap="true"
            :color="stockLabel.color"
            :text="stockLabel.textShort"
          />
        </div>
        <div v-if="userStore.isLoggedIn">
          <!-- add to cart or notify -->
          <div class="ml-12 bg-lightest p-4 items-center text-center block">
            <!-- sold out -->
            <div
              v-if="!globalStore.getAllowBackorders && !product.canAddToCart"
              class="h-full w-full"
              @click="showNotifyModal"
            >
              <button
                class="h-32"
              >
                {{ $t('notify.openModalButton') }}
              </button>
            </div>
            <Tooltip
              v-else-if="userStore.isFinanceUser"
              :show-on-hover="true"
              :text="$t('priceInfo.financeUserTooltip')"
            >
              <div class="flex items-center">
                <button
                  class="w-32 h-32 bg-lighter flex items-center"
                  :class="{
                    'pointer-events-none': true,
                  }"
                  :disabled="true"
                >
                  <img class="w-20 m-auto" src="/icons/minus.svg">
                </button>
                <div class="px-20">{{ quantity }}</div>
                <button
                  class="w-32 h-32 bg-darkest flex items-center"
                  :class="{
                    'pointer-events-none': true,
                  }"
                  :disabled="true"
                >
                  <img class="w-20 m-auto" src="/icons/plus-inv.svg">
                </button>
              </div>
            </Tooltip>
            <div
              v-else
              class="flex items-center select-none p-4"
              :class="{
                'pointer-events-none opacity-70': cartStore.isLoading,
              }"
            >
              <button
                class="w-24 h-24 flex items-center justify-center group"
                :class="{ 'pointer-events-none': quantity === 0 }"
                :disabled="quantity === 0"
                @click="remove"
              >
                <img
                  src="/icons/minus.svg"
                  class="
                  w-16 h-16 outline outline-8 outline-transparent transition-all
                  group-hover:bg-brandLight group-hover:outline-brandLight"
                  :class="{ 'opacity-50': quantity === 0 }"
                  alt=""
                >
              </button>

              <div class="w-32 h-24 inline-flex items-center justify-center type-headline-sm leading-single">
                {{ quantity }}
              </div>

              <button
                class="w-24 h-24 flex items-center justify-center group"
                :disabled="!globalStore.getAllowBackorders && !isInStock"
                @click="add"
              >
                <img
                  src="/icons/plus.svg"
                  class="
                  w-16 h-16 outline outline-8 outline-transparent transition-all
                  group-hover:bg-brandLight group-hover:outline-brandLight"
                  alt=""
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- small screen moved stock and discount -->
    <div
      v-if="(userPrice.price?.isCustomerPrice && userPrice.price?.discountPercentage > 0) || userPrice.price?.isCampaignPrice"
      class="tabletPortrait:hidden mt-8 text-right pr-[52px]"
    >
      <div>
        {{ $t('productPage.price.original') }} {{ userPrice.price?.priceBeforeDiscountBeforeVatDisplay }}
        <span v-if="userPrice.price?.isCustomerPrice">
          ({{ $t('productPage.price.yourDiscount') }} <span
            class="text-criticalDark"
          >{{ userPrice.price?.discountPercentage }}%</span>)
        </span>
      </div>
      <StockStatusDesign
        v-if="stockLabel.type !== 'active' && stockLabel.type !== 'soldout'"
        class="font-normal justify-end"
        :nowrap="true"
        :color="stockLabel.color"
        :text="stockLabel.textShort"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUiStore } from '~/store/ui';
import { useUserStore } from '~/store/user';
import { useCartStore } from '~/store/cart';
import { useGlobalContentStore } from '~/store/globalContent';
import { ProductVariantModel } from '~/models/productVariant';
import Tooltip from '~/components/body/Tooltip.vue';
import useStockStatus, { StockLabel } from '~/composeables/useStockStatus';
import StockStatusDesign from '~/components/product/StockStatusDesign.vue';

const uiStore = useUiStore();
const userStore = useUserStore();
const cartStore = useCartStore();
const globalStore = useGlobalContentStore();

const lastSent = ref(-1);

const quantity = ref(0);

const isInStock = computed(()=> {
  if (props.product.isOnDemandProduct) {
    return true;
  }
  const inStock = props.product.stockStatus?.inStock || 0;
  return inStock >= quantity.value + 1;
});

const props = defineProps<{
  product: ProductVariantModel,
}>();

const showLogin = () => {
  uiStore.setShowSigninModal(true, true);
};
const { getStockLabel } = useStockStatus();
const stockLabel = computed<StockLabel>(() => {
  return getStockLabel(props.product);
});

const showNotifyModal = () => {
  uiStore.notifyModalPartNo = props.product.partNo;
  uiStore.setShowNotify(true);
};

const userPrice = computed(()=> {
  return userStore.getPrice(props.product.partNo);
});

const add = () => {
  quantity.value++;
  startDebounce();
};

const remove = () => {
  quantity.value--;
  startDebounce();
};

let isUpdating = ref(false);
const timer = ref<ReturnType<typeof setTimeout> | undefined>(undefined);
const startDebounce = () => {
  isUpdating.value = true;

  clearTimeout(timer.value);
  timer.value = setTimeout(() => changeCue(), 500);
};

const changeCue = () => {
  clearTimeout(timer.value);
  const requestFn = async() => {
    try {
      const findLineNo = cartStore.cart.items.find((f) => f.partNo === props.product.partNo && !f.priceListLocked && !f.parentLineNo);

      await cartStore.updateCart(
        props.product.partNo,
        quantity.value,
        findLineNo?.lineNo ?? -1,
        false,
        props.product
      );
    } catch (error) {
      console.error(`Failed to update item ${props.product.partNo}`, error);
    }
  };

  addRequestToQueue(requestFn);
};

const requestQueue = [] as any;
const isProcessing = ref(false);
const addRequestToQueue = (requestFn) => {
  requestQueue.push(requestFn);
  if (!isProcessing.value) {
    processQueue();
  }
};

const processQueue = async() => {
  isProcessing.value = true;

  while (requestQueue.length > 0) {
    const currentRequest = requestQueue.shift();
    await currentRequest();
    isUpdating.value = false;
  }
  isProcessing.value = false;
};

// On browser reload, wait for cart to load
watch(()=> cartStore.loadingCart, (newVal) => {
  if (newVal === false) {
    setAmountFromCart();
  }
});

onMounted(()=> {
  setAmountFromCart();
});

const setAmountFromCart = () => {
  quantity.value = cartStore.getQuantityFromPartNo(props.product.partNo);
  lastSent.value = quantity.value;
};

const isNews = computed(()=> {
  return props.product.flags.some((s) => s.code === 'news-variant');
});

// Listen to changes from minicart
watch(()=> cartStore.getQuantityFromPartNo(props.product.partNo), () => {
  setAmountFromCart();
});

</script>

<style scoped lang="postcss">
.imageOverlay {
  background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF00 0%, #0000000A 100%) 0% 0% no-repeat padding-box;
}
</style>
